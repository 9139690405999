<template>
  <div>
    <div>
      {{ rating }}
    </div>
    <small v-if="comment">
      {{ comment }}
    </small>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'ChatRating',
  props: {
    chat: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters('tenants', ['getConfig']),
    ...mapGetters('agent', ['maxRole']),
    chatId() {
      return this.chat.chat_id;
    },
    comment() {
      return this.chat.chat_rating_comment;
    },
    rating() {
      if (this.chat?.chat_rating === null) return '';
      const rating = Number(this.chat.chat_rating);
      if (!this.ratingConfig || rating === -1) return '';
      return this.formatRating(rating);
    },
    tenantId() {
      return this.chat.tenant_id;
    },
    generalConfig() {
      return this.getConfig({ tenantId: this.tenantId, tag: 'general' });
    },
    ratingConfig() {
      return this.generalConfig?.chat_rating || null;
    },
  },
  methods: {
    formatRating(percentage) {
      let rating = Math.floor((percentage / 100) * this.ratingConfig.scale);
      if (this.ratingConfig.scale !== rating) rating += 1;
      return this.$tc('vocabulary.star', rating, { number: rating });
    },
  },
};
</script>
