export const ssoConfigIds = Object.freeze({
  CUSTOMER: 1,
  SUPWIZ: 2,
  PUZZEL: 3,
})

export function createSsoEndpoints(backendPath) {
  return {
    ssoEnabled: `${backendPath}/sso/enabled/${ssoConfigIds.CUSTOMER}`,
    ssoLogin: `${backendPath}/sso/login/${ssoConfigIds.CUSTOMER}`,
    ssoLoginSupwiz: `${backendPath}/sso/login/${ssoConfigIds.SUPWIZ}`,
    ssoConfig: `${backendPath}/sso/config/`,
    ssoExpiration: `${backendPath}/sso/expiration/`,
  }
}

export function createSsoMethods(axios, endpoints, jwt, bearerType = 'JWT') {
  return {
    async fetchMethod(id) {
      const resp = await axios.get(`${endpoints.ssoConfig}${id}`, {
        headers: { Authorization: `${bearerType} ${jwt}` },
      });
      return resp.data;
    },
    async updateMethod(id, data) {
      const resp = await axios.patch(
        `${endpoints.ssoConfig}${id}`,
        data,
        {
          headers: { Authorization: `${bearerType} ${jwt}` },
        },
      );
      return resp.data;
    },
    async expirationMethod(id) {
      const resp = await axios.get(`${endpoints.ssoExpiration}${id}`, {
        headers: { Authorization: `${bearerType} ${jwt}` },
      });
      return resp.data;
    },
  }
}
